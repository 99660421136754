<template>
  <div v-loading="loading">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1>Invoicing Summaries</h1>
      <div>
        <el-input v-model="search" placeholder="Search" />
      </div>
    </el-row>
    <el-row>
      <data-table :data="filteredGroups" height="calc(100vh - 330px)">
        <el-table-column
          label="Group Name"
          prop="name"
          sortable
        >
          <template slot-scope="scope">
            <div>
              <i v-if="!scope.row.parent" class="el-icon-star-on" />
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Company Name"
          prop="company.human_name"
          sortable
        />
        <el-table-column
          label="Invoice Recipients"
          prop="member_count"
          sortable
        />
        <el-table-column
          label="Properties"
          prop="propertyCount"
          sortable
        />
        <el-table-column align="center" width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="editGroup(scope.row)">
              Edit Group
            </el-button>
          </template>
        </el-table-column>
      </data-table>
    </el-row>
    <group-edit
      :group="groupToEdit"
      :type="enums.groupTypes.INVOICING"
      :visible.sync="showEditGroupDrawer"
      @close="closeEditGroupDrawer"
      @delete="showGroupDeleteDialog = true"
      @refresh="refreshEdit"
    />
    <group-delete-dialog
      :show="showGroupDeleteDialog"
      :group="groupToEdit"
      @close="showGroupDeleteDialog = false"
      @delete="deleteGroup(groupToEdit)"
    />
  </div>
</template>

<script>
import InvoicesAPI from '@/services/api/invoices'
import DataTable from '@/components/tables/DataTable'
import GroupEdit from '@/views/posting-groups/groups/_components/GroupEdit/GroupEdit'
import GroupDeleteDialog from '@/views/posting-groups/groups/_components/GroupDeleteDialog'
import { propertyCount } from '@/utils/groups'
import { enums } from '@/utils/constants'
export default {
  name: 'InvoicingSummaries',
  components: {
    'data-table': DataTable,
    'group-edit': GroupEdit,
    'group-delete-dialog': GroupDeleteDialog
  },
  data () {
    return {
      groups: [],
      search: '',
      loading: false,
      showEditGroupDrawer: false,
      showGroupDeleteDialog: false,
      groupToEdit: {},
      groupEdited: false
    }
  },
  computed: {
    filteredGroups () {
      const query = this.search.toLowerCase()
      return this.groups.filter(group => group.name.toLowerCase().includes(query) ||
      group.company.human_name.toLowerCase().includes(query))
    }
  },
  created () {
    this.enums = enums
    this.fetchGroups()
  },
  methods: {
    /**
     * Calls API to fetch invoicing groups.
     */
    async fetchGroups () {
      try {
        this.loading = true
        const response = await InvoicesAPI.groups.list()

        const data = []
        for (const group of response) {
          // top level group
          if (group.member_count > 0) {
            group.name = `${group.company.human_name} Entire Company`
            group.description = 'This is the default invoicing group for the entire company. Recipients of this group will receive the summary for all properties in this company. This group cannot be deleted.'
            data.push({
              ...group,
              propertyCount: propertyCount(group)
            })
          }
          for (const child of group.children) {
            if (child.member_count > 0) {
              data.push({
                ...child,
                propertyCount: propertyCount(child)
              })
            }
          }
        }
        this.groups = data
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Opens the edit group drawer.
     *
     * @param {Object} group - row object
     */
    editGroup (group) {
      this.groupToEdit = group
      this.showEditGroupDrawer = true
    },
    /**
     * Calls API to delete a Group.
     *
     * @param {Object} group
     */
    async deleteGroup (group) {
      this.showEditGroupDrawer = false
      this.showGroupDeleteDialog = false
      this.loading = true
      try {
        await InvoicesAPI.groups.delete(group.id, { force_delete_children: true })
        this.$message({
          message: `Group ${group.name} deleted.`,
          type: 'success',
          duration: 3500
        })
        this.fetchGroups()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Re-fetches the group from the API.
     */
    async refreshEdit () {
      this.groupToEdit = await InvoicesAPI.groups.retrieve(this.groupToEdit.id)
      if (!this.groupToEdit.name) {
        this.groupToEdit.name = `${this.groupToEdit.company.human_name} Entire Company`
        this.groupToEdit.description = 'This is the default invoicing group for the entire company. Recipients of this group will receive the summary for all properties in this company. This group cannot be deleted.'
      }
      this.groupEdited = true
    },
    /**
     * Closes the edit group drawer, and refreshes
     * the group list if group has been modified.
     */
    closeEditGroupDrawer () {
      this.showEditGroupDrawer = false
      if (this.groupEdited) {
        this.fetchGroups()
        this.groupEdited = false
      }
    }
  }
}
</script>
